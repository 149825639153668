.App {
  text-align: left;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  height: 90vh;
  margin-top: 5vh;
}

.content {
  padding: 4rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 10vh;
  background-color: white;
}
.row {
  width: 100%;
}

h1, h2, h3, h4, h5, p{
  margin: 0
}
h1 {
  font-size: 1.3rem;
  font-weight: 500;
  text-align: left;
  letter-spacing: .1rem;
}
h2 {
  font-size: 1.45rem;
  font-weight: 600;
  line-height: 1.65;
}

h3 {
  color: black;
  font-weight: bold;
  font-size: 0.94rem; 
  letter-spacing: 0.1rem;
  padding: 20px 0;
  text-align: left;
  white-space: nowrap;
  font-style: oblique;
}



h4 {
  color: gray;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.65;
  text-align: left;
}

h5 {
  color: gray;
  margin: 0.5rem 0;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.65;
  text-align: left;
}

h6 {
  font-size: 1rem;
  transition: 0.5s;
  margin: .5rem 0;
  padding: .5rem .3rem;
  border-radius: 5px;
  transition-timing-function: ease-out;
}

.icon {
  color: black;
  transition: 0.5s;
  transition-timing-function: ease-out;
  padding-right: 25px;
}

h6:hover, .icon:hover{
  cursor: pointer;
}

h6:hover {
  background-color: rgba(92, 93, 111, 0.3);
}


p {
  max-width: 80rem;
  text-align: left;
}

ul {
  text-align: left;
  list-style: none; 
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

a:link, a:visited, a:hover, a:active {
  text-decoration: none;
}

a:visited {
  color: black
}